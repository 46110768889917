
import { defineComponent } from "vue";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "broker-clients-policy-table-dropdown",
  components: {},
  props: {
    publicId: String,
    code: String,
  },
  setup() {
    const { reinitialization, useReusableNavigation } = ResusableFunctions();

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
});
